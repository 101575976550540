<template>
  <div id="supply-document-pdf">
    <div v-if="showPrint && !supplyPdfLoading" class="print-btn">
      <b-button id="supply-document-pdf-btn" v-ripple.400 @click="generateReport()">
        <feather-icon icon="FileTextIcon" size="20" class="align-middle" />
        <span class="ml-25 align-middle"> {{ $t('action.print') }} </span>
      </b-button>
    </div>
    <!-- @progress="onProgress($event)" -->
    <section>
      <vue-html2pdf
        ref="supply-pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        :filename="`${title} #${supply.id}`"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        :manual-pagination="true"
        @beforeDownload="beforeDownload($event)"
      >
        <template slot="pdf-content">
          <section class="invoice-preview pdf-item">
            <supply-document id="invoice-document" :supply="supply" :title="title" :invoice-configuration="invoiceConfiguration" print-mode />
          </section>
          <slot name="additional" />
        </template>
      </vue-html2pdf>
    </section>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import SupplyDocument from '@/components/SupplyDocument.vue'

export default {
  name: 'SupplyDocumentPdf',

  components: {
    VueHtml2pdf,
    SupplyDocument,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    supply: {
      type: Object,
      default: null,
    },

    invoiceConfiguration: {
      type: Object,
      default: null,
    },

    // showPaymentTerm: {
    //   type: Boolean,
    //   default: false,
    // },

    // showPaymentMethod: {
    //   type: Boolean,
    //   default: false,
    // },

    // showPaymentMethodChoices: {
    //   type: Boolean,
    //   default: false,
    // },

    showPrint: {
      type: Boolean,
      default: false,
    },

    base64Mode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      supplyPdfLoading: false,
    }
  },

  methods: {
    generateReport() {
      this.$emit('supply-pdf-loading', true)
      this.supplyPdfLoading = true
      this.$refs['supply-pdf'].generatePdf()
    },

    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .set({ margin: [0.65, 0, 0.65, 0], pagebreak: { mode: ['avoid-all'] } })
        .toPdf()
        .get('pdf')
        .then(pdf => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let page = 1; page <= totalPages; page++) {
            pdf.setPage(page)
            pdf.setFontSize(8)
            pdf.setTextColor(150)
            pdf.text(
              this.$t('supply_document.pagination', { page, totalPages }),
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 0.3
            )
            pdf.setProperties({
              title: `${this.title} #${this.supply.id}`,
              subject: this.title || '',
              author: this.supply.organization.defaultAddress.contactName || '',
              keywords: `COPYRIGHT  © ${new Date().getFullYear()} MyFlight Connect, ${this.$t('footer.all_rights_reserved')}`,
              creator: 'MyFlight Connect',
            })
          }
          if (!this.base64Mode) {
            window.open(pdf.output('bloburl'), '_blank')
          }
        })
        .outputPdf()
        .then(pdf => {
          if (this.base64Mode) {
            this.$emit('pdf-file-base64', btoa(pdf))
          }
        })
        .then(() => {
          this.supplyPdfLoading = false
          this.$emit('supply-pdf-loading', false)
        })
    },

    // onProgress(e) {
    //   if (e < 100) this.supplyPdfLoading = true
    //   else this.supplyPdfLoading = false
    // },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss" scoped>
.print-btn {
  position: fixed;
  bottom: 5%;
  right: 26px;
  z-index: 1031;
  .btn {
    box-shadow: 0 1px 20px 1px $primary !important;
    &:hover {
      box-shadow: none !important;
    }
  }
}
</style>